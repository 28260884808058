<template>
  <div>
    <table>
      <thead>
        <th class="num">编号</th>
        <th class="collaborate">合作单位/服务项目</th>
        <th class="owner">车主信息/事故点</th>
        <th class="charge">收费类型</th>
        <th class="money">金额</th>
        <th class="addtime">添加时间</th>
        <th class="team">协作商信息</th>
        <th class="operate">操作</th>
      </thead>
      <tbody>
        <tr>
          <td class="td-num">#2021121212345</td>
          <td class="td-collaborate">人保宁波分公司/<i>非事故拖车</i></td>
          <td class="td-owner">
            <strong>林先生/浙B88888</strong>
            <br />
            <span>宁波市中山路1023号</span>
          </td>
          <td class="td-charge">挂账</td>
          <td class="td-money">140</td>
          <td class="td-addtime">2019-12-24 12:15:00</td>
          <td class="td-team">宁波小修联系服务有限公司 拖师傅 13708903456</td>
          <td class="td-operate">
            <i class="check">查看</i>
            <i class="tiao"></i>
            <i class="enter">入账</i>
            <i class="tiao"></i>
            <i class="adjust">核算</i>
          </td>
        </tr>
        <tr>
          <td class="td-num">#2021121212345</td>
          <td class="td-collaborate">人保宁波分公司/<i>非事故拖车</i></td>
          <td class="td-owner">
            <strong>林先生/浙B88888</strong>
            <br />
            <span>宁波市中山路1023号</span>
          </td>
          <td class="td-charge">挂账</td>
          <td class="td-money">140</td>
          <td class="td-addtime">2019-12-24 12:15:00</td>
          <td class="td-team">宁波小修联系服务有限公司 拖师傅 13708903456</td>
          <td class="td-operate">
            <i class="check">查看</i>
            <i class="tiao"></i>
            <i class="enter">入账</i>
            <i class="tiao"></i>
            <i class="adjust">核算</i>
          </td>
        </tr>
        <tr>
          <td class="td-num">#2021121212345</td>
          <td class="td-collaborate">人保宁波分公司/<i>非事故拖车</i></td>
          <td class="td-owner">
            <strong>林先生/浙B88888</strong>
            <br />
            <span>宁波市中山路1023号</span>
          </td>
          <td class="td-charge">挂账</td>
          <td class="td-money">140</td>
          <td class="td-addtime">2019-12-24 12:15:00</td>
          <td class="td-team">宁波小修联系服务有限公司 拖师傅 13708903456</td>
          <td class="td-operate">
            <i class="check">查看</i>
            <i class="tiao"></i>
            <i class="enter">入账</i>
            <i class="tiao"></i>
            <i class="adjust">核算</i>
          </td>
        </tr>
        <tr>
          <td class="td-num">#2021121212345</td>
          <td class="td-collaborate">人保宁波分公司/<i>非事故拖车</i></td>
          <td class="td-owner">
            <strong>林先生/浙B88888</strong>
            <br />
            <span>宁波市中山路1023号</span>
          </td>
          <td class="td-charge">挂账</td>
          <td class="td-money">140</td>
          <td class="td-addtime">2019-12-24 12:15:00</td>
          <td class="td-team">宁波小修联系服务有限公司 拖师傅 13708903456</td>
          <td class="td-operate">
            <i class="check">查看</i>
            <i class="tiao"></i>
            <i class="enter">入账</i>
            <i class="tiao"></i>
            <i class="adjust">核算</i>
          </td>
        </tr>
      </tbody>
    </table>

       <!-- 分页器 -->
    <div class="jinXing-sorter">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="datas.perpage"
        @prev-click="prev_view"
        @next-click="next_view"
        @size-change="size_view"
        @current-change="current_view"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from "../api/order";
export default {
  props:['isfsx','newdatas'],
  data() {
    return {
      pagesize: 1,
      total:100,
      datas: {
        page: 1,
        perpage: 7,
      },
    }
  },
  async created(){

  }
};
</script>

<style lang="less" scoped>
table {
  width: 1610px;
  margin-left: 30px;
}
thead {
  text-align: left;
  width: 1610px;
  height: 50px;
  box-sizing: border-box;
  background-color: #f8fafc;
}
.num,
.collaborate,
.owner,
.charge,
.money,
.addtime,
.team,
.operate {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
td{
    height: 70px;
}
.td-num,
.td-collaborate,
.td-charge,
.td-money,
.td-addtime,
.td-team {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #727e96;
  opacity: 1;
}
i {
  font-style: normal;
  color: #2c68ff;
}
.td-num {
 padding-right: 54px;
  width: 116px;
}
.td-collaborate {
    padding-right: 66px;
  width: 174px;
}
.td-owner {
    padding-right: 75px;
  width: 145px;
  height: 38px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #727e96;
  opacity: 1;
}
span {
  float: left;
  margin-top: 20px;
}
.td-charge {
    padding-right: 92px;
  width: 28px;
}
.td-money {
    padding-right: 85px;
  width: 25px;
}
.td-addtime {
    padding-right: 54px;
  width: 138px;
}
.td-team {
    padding-right: 67px;
  width: 310px;
}
.tiao {
  display: inline-block;
  width: 0px;
  height: 14px;
  margin-right: 10px;
  margin-left: 10px;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  opacity: 1;
}
.check,.enter,.adjust {
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 32px;
  color: #2c68ff;
  opacity: 1;
}
.check {
  width: 32px;
}
.enter {
  width: 32px;
}
.adjust {
  width: 32px;
}

.jinXing-sorter {
  position: absolute;
  top: 835px;
  right: 30px;
}
</style>
